import * as React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import Table from '../components/Table';

const AllPage = ({ data, location }) => {
  return (
    <Layout location={location}>
      <title>All plugins &amp; themes</title>
      <h1>
        {`All plugins & themes (${
          data.themes.nodes.length + data.plugins.nodes.length
        }`}
        )
      </h1>
      <h2>{`Themes (${data.themes.nodes.length}`})</h2>
      {data?.plugins?.nodes && Table({ data: data.themes.nodes })}
      <h2>{`Plugins (${data.plugins.nodes.length}`})</h2>
      {data?.plugins?.nodes && Table({ data: data.plugins.nodes })}
    </Layout>
  );
};

export const query = graphql`
  {
    matomo: allMatomo {
      nodes {
        id
        latestVersion
        lastUpdated
      }
    }
    themes: allMatomoTheme(sort: { order: DESC, fields: lastUpdated }) {
      nodes {
        id
        idPath
        latestVersion
        lastUpdated
        isPaid
        isCustomPlugin
        createdDateTime
        description
        displayName
        numDownloads
        repositoryUrl
      }
    }
    plugins: allMatomoPlugin(
      sort: { order: DESC, fields: lastUpdated }
      filter: { isPaid: { eq: false } }
    ) {
      nodes {
        id
        latestVersion
        lastUpdated
        isPaid
        isCustomPlugin
        createdDateTime
        description
        displayName
        numDownloads
        repositoryUrl
        owner
      }
    }
    pluginsPayed: allMatomoPlugin(
      sort: { order: DESC, fields: lastUpdated }
      filter: { isPaid: { eq: true } }
    ) {
      nodes {
        id
        latestVersion
        lastUpdated
        isPaid
        isCustomPlugin
        createdDateTime
        description
        displayName
        numDownloads
        repositoryUrl
      }
    }
  }
`;

export default AllPage;
